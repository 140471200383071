import React from "react";
import IndustryInspector from "./IndustryInspector";
import "./LocalExplanations.css";

const LocalExplanations = () => {
  return (
    <div className="local-explanations-container">
      <div className="quote-container">
        <h1 className="quote">
          “Our users can’t trust our model, they won’t use it”
        </h1>
        <p>
          Use{" "}
          <a href="https://docs.decima2.co.uk/docs/coming-soon/individual-feature-importance">
            local explanations
          </a>{" "}
          to increase user trust → <br></br>
          <br></br>Local explanations give the feature importance for a single
          prediction
        </p>
      </div>
      <div className="industry-inspector-container">
        <IndustryInspector selectedTab={"Medicine"} setSelectedTab={() => {}} />
      </div>
    </div>
  );
};
export default LocalExplanations;

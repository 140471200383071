import React from "react";
import "./FullScreenMessage.css";

const FullScreenMessage = () => {
  return (
    <div className="full-screen-message">
      <p>
        Use{" "}
        <a href="https://docs.decima2.co.uk/docs/category/-model-explanations">
          {" "}
          Decima2 explainability tools
        </a>{" "}
        to enhance AI transparency and user confidence, enabling you to unlock
        new <strong>revenue opportunities</strong>{" "}
      </p>
    </div>
  );
};

export default FullScreenMessage;

import "./App.css";
import NavBar from "./NavBar";
import React, { useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import WaitingList from "./WaitingList";
import FullScreenMessage from "./FullScreenMessage";
import LocalExplanations from "./LocalExplanations";

import GlobalExplanations from "./GlobalExplanations";

const App = () => {
  const myRef = useRef(null); // Create a ref that will store a DOM element

  useEffect(() => {
    // Get the current hash from the URL
    const hash = window.location.hash;

    const element = document.getElementById(hash.slice(1));

    // Scroll to the element if it exists
    if (element) {
      console.log("scrolling to", element);
      // TODO: hire FE dev to fix this
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "center",
        });
      }, 150);
    }
  }, []);

  const pythonCode = `pip install decima2
from decima2 import model_feature_importance
app = model_feature_importance(X,y,model,output='dynamic')
app.run_server()`;

  return (
    <div className="App">
      <NavBar />
      <div className="row">
        <h1 className="App-header">Tools to deploy AI you can trust</h1>
      </div>

      {/* <div className="row" id="ToolDisplay">
        <ToolDisplay></ToolDisplay>
      </div> */}
      <div className="row" id="local_explanations">
        <LocalExplanations selectedTab={"Medicine"} setSelectedTab={() => {}} />
      </div>
      <div className="row" id="full-screen-message">
        <FullScreenMessage />
      </div>
      <div className="row" id="global-explanations">
        <GlobalExplanations />
      </div>
      <div className="row" id="codeExample">
        <p>
          Use directly in your{" "}
          <a href="https://pypi.org/project/decima2/">Python</a> project
        </p>
        <div className="Small-code-box">
          <SyntaxHighlighter
            language="python"
            style={coy}
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
            }}
            wrapLines={true}
            showLineNumbers
          >
            {pythonCode}
          </SyntaxHighlighter>
        </div>
      </div>
      <div className="row" ref={myRef} id="waiting-list">
        <WaitingList />
      </div>
    </div>
  );
};

export default App;

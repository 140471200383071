import React, { useState } from "react";
import "./WaitingList.css";

import { collection, addDoc, serverTimestamp } from "firebase/firestore/lite";
import { db } from "./firebase";

const WaitingList = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(db);
      await addDoc(collection(db, "waitingList"), {
        email: email,
        timestamp: serverTimestamp(),
      });
      setMessage("Thank you! You have been added to the waiting list.");
      setEmail("");
    } catch (error) {
      console.error("Error adding email to waiting list: ", error);
      setMessage("Sorry, there was an error. Please try again later.");
    }
  };

  return (
    <div className="waiting-list">
      <h2>Join the Waiting List</h2>
      <p>Be the first to know when new tools are available.</p>
      <div className="waiting-list-form">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
      {message && <p>{message}</p>}
      <p>
        Alternatively, contact the team on{" "}
        <a href="mailto:hello@decima2.co.uk">hello@decima2.co.uk</a>
      </p>
    </div>
  );
};

export default WaitingList;

import React from "react";
import "./NavBar.css"; // Assuming you have a CSS file for styling
require("typeface-inconsolata");

const NavBar = () => {
  return (
    <nav className="navbar">
      <ul className="nav-links">
        <li className="nav-item logo">DECIMA2</li>
        <li className="nav-item"></li>
        {/* when we hire a real FE
         <li className="nav-item right">
          <a href="https://decima2docs.web.app/docs/intro/">
            {}
            <img src="img/github.png" alt="github" class="nav-logo" />
          </a>
        </li>
        <li className="nav-item right">
          <a href="https://decima2docs.web.app/docs/intro/">
            {}
            <img src="img/Pypi.png" alt="github" class="nav-logo" />
          </a>
        </li> */}
        <li className="nav-item right" id="devdocs">
          <a href="https://docs.decima2.co.uk/docs/intro/">Developer docs</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;

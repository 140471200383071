import React from "react";
import { Bar } from "react-chartjs-2";
import "./DecisionInspector.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.color = "#fff";
ChartJS.defaults.font.color = "#fff";

const DecisionInspector = ({ modelOutput, textExplanation, barChartData }) => {
  const data = {
    labels: barChartData.labels,
    datasets: [
      {
        label: "Values",
        data: barChartData.values,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    categoryPercentage: 0.7,
    responsive: true,
    scales: {
      x: {
        type: "category",
        title: {
          display: false,
          text: "Metrics",
        },
        scales: { barPercentage: 0.9, categoryPercentage: 0.8 },
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Values",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div id="decision-inspector-table">
      <div class="decision-inspector-row">
        <h2>Model Output</h2>
        <p>{modelOutput}</p>
      </div>
      <div class="decision-inspector-row">
        <h2>Text Explanation</h2>
        <p>{textExplanation}</p>
      </div>
      <div class="decision-inspector-row">
        <h2>Chart Explanation</h2>
        <div
          style={{
            position: "relative",
            margin: "auto",
            width: "99%",
            height: "99%",
          }}
        >
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default DecisionInspector;

import React, { useState } from "react";
import "./ModelFilter.css";
import ModelInspector from "./ModelInspector";

const ModelFilter = () => {
  const [selectedTab, setSelectedTab] = useState("All Users");

  const data = {
    "All Users": {
      barChartData: {
        labels: [
          "customer_support_interaction",
          "billing_issue",
          "subscription_discount",
        ],
        values: [3, 2, 1],
      },
    },
    "Women Over 60": {
      barChartData: {
        labels: ["customer_tenure", "contract_length", "purchase_frequency"],
        values: [15, 25, 35],
      },
    },
    "Men Over 60": {
      barChartData: {
        labels: [
          "competitor_interaction",
          "billing_issue",
          "purchase_frequency",
        ],
        values: [20, 50, 40],
      },
    },
    "Customers with discount": {
      barChartData: {
        labels: [
          "subscription_discount",
          "purchase_frequency",
          "customer_support_interaction",
        ],
        values: [20, 70, 90],
      },
    },
    "Customers without discount": {
      barChartData: {
        labels: ["customer_tenure", "purchase_frequency", "contract_length"],
        values: [20, 50, 40],
      },
    },
  };

  const selectedData = data[selectedTab];

  return (
    <div class="model-inspector-container">
      <div class="ais-Menu">
        <ul class="ais-Menu-list">
          {Object.keys(data).map((tab) => (
            <li
              id={tab}
              onClick={() => setSelectedTab(tab)}
              className={
                selectedTab === tab
                  ? "ais-Menu-item ais-Menu-item--selected"
                  : "ais-Menu-item"
              }
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {selectedData && (
        <ModelInspector barChartData={selectedData.barChartData} />
      )}
      <p>
        for customers with discount, the most important feature was ‘customer
        support’ - to increase customer retention try more emails to customers
        with discount
      </p>
    </div>
  );
};

export default ModelFilter;

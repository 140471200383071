import React, { useState } from "react";
import DecisionInspector from "./DecisionInspector";
import "./IndustryInspector.css";

const IndustryInspector = () => {
  const [selectedTab, setSelectedTab] = useState("Diagnosis");

  const data = {
    Diagnosis: {
      modelOutput: "Heart_disease_risk for patient_1432 = 89%",
      textExplanation:
        "The most important feature in this decision was age followed by blood pressure then cholesterol.",
      barChartData: {
        labels: ["age", "blood pressure", "cholesterol"],
        values: [3, 2, 1],
      },
    },
    "Churn prediction": {
      modelOutput: "The churn_likelihood for customer_123 = 70%",
      textExplanation:
        "The most important feature in this decision was bug_reports followed by contract_length then price_sensitivity.",
      barChartData: {
        labels: ["price_sensitivity", "contract_length", "bug-reports"],
        values: [15, 25, 35],
      },
    },
    Search: {
      modelOutput:
        "For the search term 'blue bags for work' The ranking position for record_123 = 4",
      textExplanation:
        "The most important word in this decision was 'blue' followed by 'work' then 'bag'.",
      barChartData: {
        labels: ["bag", "blue", "work"],
        values: [20, 50, 40],
      },
    },
    Recommender: {
      modelOutput:
        "For user_1593 the recommended position for product_123 is 1",
      textExplanation:
        "The most important preference in this decision was genre:horror.",
      barChartData: {
        labels: ["film_length: <2:00", "genre:horror", "time_of_day:evening"],
        values: [25, 65, 45],
      },
    },
    "Sentiment analysis": {
      modelOutput: "For user review_123 the sentiment is 89% positive",
      textExplanation:
        "Tthe phrases ‘i liked the color’ ‘beautiful shape’ were most influential in raising this score but ‘took a long time' was most influential in lowering this score",
      barChartData: {
        labels: [
          "‘i liked the color’",
          "'beautiful shape'",
          "‘took a long time'",
        ],
        values: [30, 40, -10],
      },
    },
  };

  const selectedData = data[selectedTab];

  return (
    <div>
      <div class="ais-Menu">
        <ul class="ais-Menu-list">
          {Object.keys(data).map((tab) => (
            <li
              id={tab}
              onClick={() => setSelectedTab(tab)}
              className={
                selectedTab === tab
                  ? "ais-Menu-item ais-Menu-item--selected"
                  : "ais-Menu-item"
              }
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {selectedData && (
        <DecisionInspector
          modelOutput={selectedData.modelOutput}
          textExplanation={selectedData.textExplanation}
          barChartData={selectedData.barChartData}
        />
      )}
    </div>
  );
};

export default IndustryInspector;

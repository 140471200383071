import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
// import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyD2Htdj0sx3QqR7eWQWSvStvAGW23BuSOY",
  authDomain: "decima2-a3d89.firebaseapp.com",
  projectId: "decima2-a3d89",
  storageBucket: "decima2-a3d89.appspot.com",
  messagingSenderId: "297287272577",
  appId: "1:297287272577:web:c04a810dee23c00b41a580",
  measurementId: "G-KP7LHGRG07",
};

const app = initializeApp(firebaseConfig);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
// export const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider("6LeHaVsqAAAAAMwNOXQ5_afCyynOi0JAVcbiA4lA"),
//   isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
// });

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);

import React from "react";
import ModelFilter from "./ModelFilter";
import "./GlobalExplanations.css";

const GlobalExplanations = () => {
  return (
    <div className="global-explanations-container">
      <div className="quote-container-horizontal">
        <h1 className="quote">"How can we extract value from AI?"</h1>
        <p>
          Use{" "}
          <a href="https://docs.decima2.co.uk/docs/explanation/grouped-feature-importance">
            global explanations
          </a>{" "}
          to get insights from your model -{">"} <br></br>
          <br></br> Global explanations show the feature importance for the
          whole model
        </p>
      </div>
      <ModelFilter />
    </div>
  );
};

export default GlobalExplanations;
